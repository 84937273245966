/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import elderlyManPark from '../../images/heroes/man-walking-through-park.jpg'
import womanTabletCouch from '../../images/people/woman-tablet-couch.jpg'
import manUsingLaptop from '../../images/people/using-laptop.jpg'
import purpleCheckMark from '../../images/icons/purple-check.svg'

import { Phone } from 'emotion-icons/material'
import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../../components/Countdown'
import DynamicCTACard from '../../components/DynamicCTACard'
import SeoCtaBar from '../../components/SeoCtaBar'

import {
  Banner,
  Columns,
  Column,
  Hero,
  Link,
  LinkButton,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/Footer'
import header from '../../components/Header'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-plan',
    'disclaimer-evidence',
    'dsiclaimer-changenotice',
    'disclaimer-aetnabrand',
    'disclaimer-clicopyright',
    'disclaimer-incompletedescrip',
    'disclaimer-cmsmaterialid',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: 'Aetna Medicare Plans 2023 | Call 833-963-1022 to learn more',
          description:
            "Depending on your plan, Medicare can help pay for your medical expenses, such as doctor's visits, hospital services, prescription drugs and medications, long-term care, or even your out-of-pocket health care costs.",
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/eligibility',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'aetnamedicare',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero background-image-position-right"
              image={
                <img
                  src={elderlyManPark}
                  alt="elderly walks through public park and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Aetna<sup>®</sup> Medicare: Parts and Plans{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />

        <CountdownTimer />
        <SplitContent
          backgroundColor="white"
          alignImageToBottom={false}
          image={
            <img
              src={womanTabletCouch}
              alt="an elderly woman sits on couch and looks at tablet"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Choosing the right Medicare plan is easy with Aetna
              </Typography>
              <Typography variant="body">
                If you’re new to Medicare, you may feel a little overwhelmed by
                the many different plans that are available. Even if you’ve been
                on Medicare for a while, you may be wondering whether it’s time
                to change your existing plan. Fortunately, our licensed agents
                are Medicare experts. They speak Medicare health plans, so you
                don’t have to. Talk to an agent today to find Medicare insurance
                plans near you.
              </Typography>
              <Banner
                backgroundColor="Primary"
                className="cta-banner column-desktop"
                mainContent={
                  <>
                    <Typography
                      variant="h5"
                      style={{ color: 'white', marginBottom: '5px' }}
                    >
                      Speak with a Licensed Agent
                    </Typography>
                    <Typography
                      variant="body"
                      style={{ color: 'white', marginBottom: '10px' }}
                    >
                      TTY: 711, available 24/7
                    </Typography>
                    <LinkButton
                      variant="feature"
                      to={`tel:${rotatedNumber}`}
                      color="light"
                      className="margin-x-auto"
                      leftIcon={<Phone />}
                      requestId={require}
                    >
                      {rotatedNumber}
                    </LinkButton>
                  </>
                }
              />
            </>
          }
        />

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">Explore Aetna Medicare plans</Typography>
              <Typography variant="body">
                Aetna offers a variety of Medicare plans to cover a broad range
                of health care services, equipment, and costs. Explore your
                options below, then speak with an agent to get started.
              </Typography>
            </>
          }
          backgroundColor="lightGray"
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-hospital.svg"
                    alt="hospital icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Part A (hospital & hospice coverage)
                  </Typography>
                  <Typography variant="body">
                    Helps cover the costs of inpatient hospital stays, skilled
                    nursing facilities, home health, and hospice care
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/parta"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-stethoscope.svg"
                    alt="stethoscope icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Part B (medical coverage & preventative care)
                  </Typography>
                  <Typography variant="body">
                    Provides insurance benefits for outpatient care,
                    preventative services, and limited prescription drugs
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/medicare-part-b"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-health.svg"
                    alt="health icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Part C (Medicare Advantage)
                  </Typography>
                  <Typography variant="body">
                    Offers all the same coverage as Medicare Parts A and B, but
                    with added benefits, such as vision, hearing, and dental
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/advantage"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">
                Medicare plans comparison chart
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <div className="comparison-table">
            <table className="table-main">
              <thead>
                <td>Features</td>
                <td>Medicare Part A</td>
                <td>Medicare Part B</td>
                <td>Medicare Part C</td>
              </thead>
              <tbody>
                <tr>
                  <td>Inpatient Hospital Care</td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                  </td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                  </td>
                </tr>
                <tr>
                  <td>Hospice Care</td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Outpatient Medical Care</td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                  </td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                  </td>
                </tr>
                <tr>
                  <td>Coverage For Out-Of-Pocket Medical Expenses</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Emergency Care While Traveling Abroad</td>
                  <td></td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                    <sup> *</sup>
                  </td>
                </tr>
                <tr>
                  <td>Routine Care, Such As Vision, Hearing & Dental</td>
                  <td></td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                    <sup> *</sup>
                  </td>
                </tr>
                <tr>
                  <td>Fitness Memberships</td>
                  <td></td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                    <sup> *</sup>
                  </td>
                </tr>
                <tr>
                  <td>Health & Wellness Programs</td>
                  <td></td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                    <sup> *</sup>
                  </td>
                </tr>
                <tr>
                  <td>Out-of-Pocket Maximum Protection</td>
                  <td></td>
                  <td></td>
                  <td>
                    <img src={purpleCheckMark} alt="purple checkmark icon" />
                    <sup> *</sup>
                  </td>
                </tr>
              </tbody>
            </table>
            <Typography variant="body" className="table-after">
              *Available only with certain Aetna Medicare health plans, not on
              all plans.
            </Typography>
          </div>
        </VariableContent>

        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={manUsingLaptop}
              alt="an elderly man sits and uses laptop while talking on mobile phone"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Enroll in a Medicare Plan Today!
              </Typography>
              <Typography variant="body">
                Ready to enroll in a plan? Get started by speaking with a
                licensed agent today at{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>.
              </Typography>
              <div className="card bg-light">
                <Typography variant="h4">
                  Request your complimentary Medicare insurance quote{' '}
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Get Quote
                </LinkButton>
              </div>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
